export const outlinedStops = {
  initial: 18,
  active: -8,
};

export const outlinedStopsDense = {
  initial: 10,
  active: -8,
};

export const nonOutlinedStops = {
  initial: 18,
  active: 5,
};
