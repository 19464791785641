import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
  containerStyle: {
    position: 'relative',
  },
  textField: {
    height: 56,
    textAlignVertical: 'bottom',
    paddingTop: 16,
  },

  flatInput: {},
});

export default styles;
